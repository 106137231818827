@import "./styles/fonts";
@import "~bootstrap/scss/bootstrap";
@import "./styles/variables";

@mixin setBorderRadius($radius: 5px) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}

@mixin setBorderRadiusTopCorners($radius: 5px) {
  -webkit-border-top-right-radius: $radius;
  -moz-border-top-right-radius: $radius;
  border-top-right-radius: $radius;
  -webkit-border-top-left-radius: $radius;
  -moz-border-top-left-radius: $radius;
  border-top-left-radius: $radius;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}

@mixin setBorderRadiusBottomCorners($radius: 5px) {
  -webkit-border-bottom-right-radius: $radius;
  -moz-border-bottom-right-radius: $radius;
  border-bottom-right-radius: $radius;
  -webkit-border-bottom-left-radius: $radius;
  -moz-border-bottom-left-radius: $radius;
  border-bottom-left-radius: $radius;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}

@mixin setBorderRadiusLeftCorners($radius: 5px) {
  -webkit-border-top-left-radius: $radius;
  -moz-border-top-left-radius: $radius;
  border-top-left-radius: $radius;
  -webkit-border-bottom-left-radius: $radius;
  -moz-border-bottom-left-radius: $radius;
  border-bottom-left-radius: $radius;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}

@mixin setBorderRadiusRightCorners($radius: 5px) {
  -webkit-border-top-right-radius: $radius;
  -moz-border-top-right-radius: $radius;
  border-top-right-radius: $radius;
  -webkit-border-bottom-right-radius: $radius;
  -moz-border-bottom-right-radius: $radius;
  border-bottom-right-radius: $radius;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}

.app-footer {
  width: 100%;
  bottom: 0px;
  z-index: 1;
  height: "-3em";
  margin-top: "40em";
}

.app-footer-dark {
  width: 100%;
  bottom: 0px;
  z-index: 1;
  height: "-3em";
  margin-top: "40em";
  background: #282A35;
  
  div, a {
    color: #ffffff !important;
  }
}

.MenuBar > div > div > a > svg {
  height: 50px;
  margin-top: 0px !important;
}

.progress-bar {
  background-color: $color-darkGrey !important;
}

.modal-dialog {
  max-width: 540px;

  .modal-content {
    border-radius: 16px;
  }
}

.btn {
  &.btn-sm-round {
    @include setBorderRadius(50%);
    margin-left: 4px;
    margin-right: 4px;
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    color: #282a35;
    border-color: #dee2e6;

    &:hover,
    &.hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      color: #282A35 !important;
      border-color: #798488 !important;
      background-color: #e7e9eb !important;
    }

    &:focus,
    &.focus,
    &:active,
    &.active {
      // box-shadow: inset 0 0 0 0.2rem rgba(52, 58, 64, 0.2) !important;
      box-shadow: inset 0 0 0.2rem 0.1rem rgba(52, 58, 64, 0.2) !important;
    }

    &.dropdown-toggle {
      &:after {
        display: none !important;
      }
    }
  }

  &.btn-md-round {
    @include setBorderRadius(50%);
    color: #282a35;
    border: 1px solid #dee2e6;
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 18px;

    &:hover,
    &.hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      color: #282A35 !important;
      border-color: #798488 !important;
      background-color: #e7e9eb !important;
    }

    &:focus,
    &.focus,
    &:active,
    &.active {
      box-shadow: inset 0 0 0.2rem 0.1rem rgba(52, 58, 64, 0.2) !important;
    }

    &.loading {
      animation: pulse 1s infinite;
      animation-direction: alternate;
      animation-timing-function: ease-in-out;
    }

    @keyframes pulse {
      0% {
        background-color: #fff;
      }
      100% {
        background-color: #dee2e6;
      }
    }

    svg {
      max-height: 18px;
      max-width: 18px;
    }

    &.btn-color-green {
      color: #fff;
      background-color: #059862;
      border-color: transparent;

      &:hover,
      &.hover,
      &:focus,
      &.focus,
      &:active,
      &.active {
        color: #fff !important;
        border-color: #fff !important;
        background-color: #03744b !important;
      }

      &:focus,
      &.focus,
      &:active,
      &.active {
        box-shadow: inset 0 0 0.2rem 0.1rem rgba(52, 58, 64, 0.8) !important;
      }
    }
  }
}

body { 
	max-width: 1440px; 
  height: 100vh;
  padding-right: 1px;
	margin: 0 auto !important; 
	float: none !important; 
  overflow-x: hidden !important;
}